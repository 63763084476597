import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import WorkFlow from "./components/workflow/WorkFlow";
import WorkFlowList from "./components/workflow/WorkFlowList";
import Error403 from "./components/Error403";
import Login from "./login";
import ZylerWorkflowList from "./components/zyler-workflow/WorkFlowList";
import ZylerWorkflow from "./components/zyler-workflow/WorkFlow";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useState } from "react";
import { Spin } from "antd";

function App() {
  const profileData = Cookies.get("profileData");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [loading, setLoading] = useState(false);
  const [workflowTrigger, setWorkflowTrigger] = useState(0);
  const verify_user = async () => {
    const Token = Cookies.get("accessToken");
    if (Token) {
      const url = `${apiUrl}/api/v1/token/verify/`;
      try {
        setLoading(true);
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
            "X-Database-Id": process.env.REACT_APP_WORKFLOW_HEADER,
          },
          body: JSON.stringify({ token: Token }),
        });
        const getData = await response.json();
        if (getData) {
          const profileData = {
            fullName: `${getData?.firstname} ${getData?.lastname}`,
            email: getData?.email,
            userid: getData?.user_id,
          };

          Cookies.set("profileData", JSON.stringify(profileData));
          setLoading(false);
          setWorkflowTrigger(workflowTrigger + 1);
          await menuClickTracking();
        } else {
          const text = await response.text(); // Get response text for debugging
          console.error("Error :", text);
        }
      } catch (error) {
        console.error("Error ", error);
      }
    }
  };

  useEffect(() => {
    if (!profileData) {
      verify_user();
    }
  }, [profileData]);
  
  const menuClickTracking = async () => {
    const Token = Cookies.get("accessToken");
    if (Token) {
      const menuClickUrl = `${apiUrl}/api/v1/menu/track-click/`;
      try {
        const response = await fetch(menuClickUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Token}`,
            "X-Database-Id": process.env.REACT_APP_WORKFLOW_HEADER,
          },
          body: JSON.stringify({ menu_id: process.env.REACT_APP_MENU_ID }),
        });

        if (response.ok) {
          await response.json();
        } else {
          const text = await response.text(); // Get response text for debugging
          console.error("Error :", text);
        }
      } catch (error) {
        console.error("Error ", error);
      }
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <Router>
          <Routes>
            <Route
              path="/"
              element={<WorkFlowList workflowTrigger={workflowTrigger} />}
            />
            <Route path="/zyler_autologin/:bearerToken" element={<Login />} />
            <Route path="/workflow/:id" element={<WorkFlow />} />
            <Route path="/erorr_403" element={<Error403 />} />
            <Route path="/zylerworkflowlist" element={<ZylerWorkflowList />} />
            <Route path="/zylerworkflowl/:id" element={<ZylerWorkflow />} />
          </Routes>
        </Router>
      </Spin>
    </div>
  );
}

export default App;
